import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import Layout from "../../components/layout";
import styled from "@emotion/styled/";

export default function WorkWear() {
    return (
        <Layout>
            <Container>
                <div className="bl-images">

                    <StaticImage
                        src="../assets/img/photo/2021051213804.jpg"
                        alt="workwear"
                        className="grid-image"

                    />
                    <StaticImage
                        src="../assets/img/photo/2021051213969.jpg"
                        alt="workwear"
                        className="grid-image"
                        objectFit="contain"
                        objectPosition="top"
                    />
                    <StaticImage
                        src="../assets/img/photo/2021051213867.jpg"
                        alt="workwear"
                        className="grid-image"
                        objectFit="contain"
                        objectPosition="top"
                    />
                    <StaticImage
                        src="../assets/img/photo/2021043013652.jpg"
                        alt="workwear"
                        className="grid-image"
                        objectFit="contain"
                        objectPosition="top"
                    />
                    <StaticImage
                        src="../assets/img/photo/2021043013678.jpg"
                        alt="workwear"
                        className="grid-image"
                        objectFit="contain"
                        objectPosition="top"
                    />
                    <StaticImage
                        src="../assets/img/photo/2021051213284.jpg"
                        alt="workwear"
                        className="grid-image"
                        objectFit="contain"
                        objectPosition="top"
                    />
                    <StaticImage
                        src="../assets/img/photo/2021051214160.jpg"
                        alt="workwear"
                        className="grid-image"
                        objectFit="contain"
                        objectPosition="top"
                    />
                    <StaticImage
                        src="../assets/img/photo/2021051213145.jpg"
                        alt="workwear"
                        className="grid-image"
                        objectFit="contain"
                        objectPosition="top"
                    />
                    <StaticImage
                        src="../assets/img/photo/2021051213131.jpg"
                        alt="workwear"
                        className="grid-image"
                        objectFit="contain"
                        objectPosition="top"
                    />
                    <StaticImage
                        src="../assets/img/workwear/gallery.png"
                        alt="workwear"
                        className="grid-image"
                        objectFit="contain"
                        objectPosition="top"
                    />
                </div>
            </Container>
        </Layout>
    );
}

const Container = styled.div`

  .bl-images {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1440px;
    margin: 0 auto 200px;

    .grid-image {
      margin-top: 120px;
      width: 100%;
      break-inside: avoid;
      
      min-width: 500px;
    }
  }
`
